@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap);
* {
  box-sizing: border-box;
}

html {
  font-family: 'Noto Sans JP', sans-serif;
}

body {
  margin: 0;
  background-color: #3f3f3f;
}

button {
  font: inherit;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  background-color: transparent;
  color: #1a8ed1;
  border: 1px solid #1a8ed1;
}

button:hover,
button:active {
  background-color: #1ac5d1;
  border-color: #1ac5d1;
  color: white;
}
.CartButton_button__3nU_b {
  background-color: transparent;
  border-color: #1ad1b9;
  color: #1ad1b9;
}

.CartButton_button__3nU_b:hover,
.CartButton_button__3nU_b:active {
  color: white;
}

.CartButton_button__3nU_b span {
  margin: 0 0.5rem;
}

.CartButton_badge__3njqM {
  background-color: #1ad1b9;
  border-radius: 30px;
  padding: 0.15rem 1.25rem;
  color: #1d1d1d;
}
.Products_products__1PCvW h2 {
  margin: 2rem auto;
  text-align: center;
  text-transform: uppercase;
}

.Products_products__1PCvW ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.Card_card__1m44e {
  margin: 1rem auto;
  border-radius: 6px;
  background-color: white;
  padding: 1rem;
  width: 90%;
  max-width: 40rem;
  border: 1px solid black;
}
.ProductItem_item__WYS0J h3 {
  margin: 0.5rem 0;
  font-size: 1.25rem;
}

.ProductItem_item__WYS0J header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: baseline;
          align-items: baseline;
}

.ProductItem_price__1GiPR {
  border-radius: 30px;
  padding: 0.15rem 1.5rem;
  background-color: #3a3a3a;
  color: white;
  font-size: 1.5rem;
}

.ProductItem_item__WYS0J p {
  color: #3a3a3a;
}

.ProductItem_actions__14Yr- {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
.loader-container {
    height: 100%;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.loader {
    margin: 100px auto;
    font-size: 25px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load5 1.1s infinite ease;
    animation: load5 1.1s infinite ease;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  @-webkit-keyframes load5 {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em #8c1d1d, 1.8em -1.8em 0 0em rgba(140,29,29, 0.2), 2.5em 0em 0 0em rgba(140,29,29, 0.2), 1.75em 1.75em 0 0em rgba(140,29,29, 0.2), 0em 2.5em 0 0em rgba(140,29,29, 0.2), -1.8em 1.8em 0 0em rgba(140,29,29, 0.2), -2.6em 0em 0 0em rgba(140,29,29, 0.5), -1.8em -1.8em 0 0em rgba(140,29,29, 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(140,29,29, 0.7), 1.8em -1.8em 0 0em #8c1d1d, 2.5em 0em 0 0em rgba(140,29,29, 0.2), 1.75em 1.75em 0 0em rgba(140,29,29, 0.2), 0em 2.5em 0 0em rgba(140,29,29, 0.2), -1.8em 1.8em 0 0em rgba(140,29,29, 0.2), -2.6em 0em 0 0em rgba(140,29,29, 0.2), -1.8em -1.8em 0 0em rgba(140,29,29, 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgba(140,29,29, 0.5), 1.8em -1.8em 0 0em rgba(140,29,29, 0.7), 2.5em 0em 0 0em #8c1d1d, 1.75em 1.75em 0 0em rgba(140,29,29, 0.2), 0em 2.5em 0 0em rgba(140,29,29, 0.2), -1.8em 1.8em 0 0em rgba(140,29,29, 0.2), -2.6em 0em 0 0em rgba(140,29,29, 0.2), -1.8em -1.8em 0 0em rgba(140,29,29, 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(140,29,29, 0.2), 1.8em -1.8em 0 0em rgba(140,29,29, 0.5), 2.5em 0em 0 0em rgba(140,29,29, 0.7), 1.75em 1.75em 0 0em #8c1d1d, 0em 2.5em 0 0em rgba(140,29,29, 0.2), -1.8em 1.8em 0 0em rgba(140,29,29, 0.2), -2.6em 0em 0 0em rgba(140,29,29, 0.2), -1.8em -1.8em 0 0em rgba(140,29,29, 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba(140,29,29, 0.2), 1.8em -1.8em 0 0em rgba(140,29,29, 0.2), 2.5em 0em 0 0em rgba(140,29,29, 0.5), 1.75em 1.75em 0 0em rgba(140,29,29, 0.7), 0em 2.5em 0 0em #8c1d1d, -1.8em 1.8em 0 0em rgba(140,29,29, 0.2), -2.6em 0em 0 0em rgba(140,29,29, 0.2), -1.8em -1.8em 0 0em rgba(140,29,29, 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(140,29,29, 0.2), 1.8em -1.8em 0 0em rgba(140,29,29, 0.2), 2.5em 0em 0 0em rgba(140,29,29, 0.2), 1.75em 1.75em 0 0em rgba(140,29,29, 0.5), 0em 2.5em 0 0em rgba(140,29,29, 0.7), -1.8em 1.8em 0 0em #8c1d1d, -2.6em 0em 0 0em rgba(140,29,29, 0.2), -1.8em -1.8em 0 0em rgba(140,29,29, 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba(140,29,29, 0.2), 1.8em -1.8em 0 0em rgba(140,29,29, 0.2), 2.5em 0em 0 0em rgba(140,29,29, 0.2), 1.75em 1.75em 0 0em rgba(140,29,29, 0.2), 0em 2.5em 0 0em rgba(140,29,29, 0.5), -1.8em 1.8em 0 0em rgba(140,29,29, 0.7), -2.6em 0em 0 0em #8c1d1d, -1.8em -1.8em 0 0em rgba(140,29,29, 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(140,29,29, 0.2), 1.8em -1.8em 0 0em rgba(140,29,29, 0.2), 2.5em 0em 0 0em rgba(140,29,29, 0.2), 1.75em 1.75em 0 0em rgba(140,29,29, 0.2), 0em 2.5em 0 0em rgba(140,29,29, 0.2), -1.8em 1.8em 0 0em rgba(140,29,29, 0.5), -2.6em 0em 0 0em rgba(140,29,29, 0.7), -1.8em -1.8em 0 0em #8c1d1d;
    }
  }
  @keyframes load5 {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em #8c1d1d, 1.8em -1.8em 0 0em rgba(140,29,29, 0.2), 2.5em 0em 0 0em rgba(140,29,29, 0.2), 1.75em 1.75em 0 0em rgba(140,29,29, 0.2), 0em 2.5em 0 0em rgba(140,29,29, 0.2), -1.8em 1.8em 0 0em rgba(140,29,29, 0.2), -2.6em 0em 0 0em rgba(140,29,29, 0.5), -1.8em -1.8em 0 0em rgba(140,29,29, 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(140,29,29, 0.7), 1.8em -1.8em 0 0em #8c1d1d, 2.5em 0em 0 0em rgba(140,29,29, 0.2), 1.75em 1.75em 0 0em rgba(140,29,29, 0.2), 0em 2.5em 0 0em rgba(140,29,29, 0.2), -1.8em 1.8em 0 0em rgba(140,29,29, 0.2), -2.6em 0em 0 0em rgba(140,29,29, 0.2), -1.8em -1.8em 0 0em rgba(140,29,29, 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgba(140,29,29, 0.5), 1.8em -1.8em 0 0em rgba(140,29,29, 0.7), 2.5em 0em 0 0em #8c1d1d, 1.75em 1.75em 0 0em rgba(140,29,29, 0.2), 0em 2.5em 0 0em rgba(140,29,29, 0.2), -1.8em 1.8em 0 0em rgba(140,29,29, 0.2), -2.6em 0em 0 0em rgba(140,29,29, 0.2), -1.8em -1.8em 0 0em rgba(140,29,29, 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(140,29,29, 0.2), 1.8em -1.8em 0 0em rgba(140,29,29, 0.5), 2.5em 0em 0 0em rgba(140,29,29, 0.7), 1.75em 1.75em 0 0em #8c1d1d, 0em 2.5em 0 0em rgba(140,29,29, 0.2), -1.8em 1.8em 0 0em rgba(140,29,29, 0.2), -2.6em 0em 0 0em rgba(140,29,29, 0.2), -1.8em -1.8em 0 0em rgba(140,29,29, 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba(140,29,29, 0.2), 1.8em -1.8em 0 0em rgba(140,29,29, 0.2), 2.5em 0em 0 0em rgba(140,29,29, 0.5), 1.75em 1.75em 0 0em rgba(140,29,29, 0.7), 0em 2.5em 0 0em #8c1d1d, -1.8em 1.8em 0 0em rgba(140,29,29, 0.2), -2.6em 0em 0 0em rgba(140,29,29, 0.2), -1.8em -1.8em 0 0em rgba(140,29,29, 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(140,29,29, 0.2), 1.8em -1.8em 0 0em rgba(140,29,29, 0.2), 2.5em 0em 0 0em rgba(140,29,29, 0.2), 1.75em 1.75em 0 0em rgba(140,29,29, 0.5), 0em 2.5em 0 0em rgba(140,29,29, 0.7), -1.8em 1.8em 0 0em #8c1d1d, -2.6em 0em 0 0em rgba(140,29,29, 0.2), -1.8em -1.8em 0 0em rgba(140,29,29, 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba(140,29,29, 0.2), 1.8em -1.8em 0 0em rgba(140,29,29, 0.2), 2.5em 0em 0 0em rgba(140,29,29, 0.2), 1.75em 1.75em 0 0em rgba(140,29,29, 0.2), 0em 2.5em 0 0em rgba(140,29,29, 0.5), -1.8em 1.8em 0 0em rgba(140,29,29, 0.7), -2.6em 0em 0 0em #8c1d1d, -1.8em -1.8em 0 0em rgba(140,29,29, 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(140,29,29, 0.2), 1.8em -1.8em 0 0em rgba(140,29,29, 0.2), 2.5em 0em 0 0em rgba(140,29,29, 0.2), 1.75em 1.75em 0 0em rgba(140,29,29, 0.2), 0em 2.5em 0 0em rgba(140,29,29, 0.2), -1.8em 1.8em 0 0em rgba(140,29,29, 0.5), -2.6em 0em 0 0em rgba(140,29,29, 0.7), -1.8em -1.8em 0 0em #8c1d1d;
    }
  }
  
.Cart_cart__3GqLz {
  max-width: 30rem;
  background-color: #313131;
  color: white;
}

.Cart_cart__3GqLz h2 {
  font-size: 1.25rem;
  margin: 0.5rem 0;
}

.Cart_cart__3GqLz ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.CartItem_item__1zMOi {
  margin: 1rem 0;
  background-color: #575757;
  padding: 1rem;
}

.CartItem_item__1zMOi h3 {
  margin: 0 0 0.5rem 0;
  font-size: 1.75rem;
}

.CartItem_item__1zMOi header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: baseline;
          align-items: baseline;
}

.CartItem_details__2_Z3n {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.CartItem_quantity__DfEXm span {
  font-size: 1.5rem;
  font-weight: bold;
}

.CartItem_price__2Nl0j {
  font-size: 1.5rem;
  font-weight: bold;
}

.CartItem_itemprice__j9ZSt {
  font-weight: normal;
  font-size: 1rem;
  font-style: italic;
}

.CartItem_actions__UwVPJ {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin: 0.5rem 0;
}

.CartItem_actions__UwVPJ button {
  background-color: transparent;
  border: 1px solid white;
  margin-left: 0.5rem;
  padding: 0.15rem 1rem;
  color: white;
}

.CartItem_actions__UwVPJ button:hover,
.CartItem_actions__UwVPJ button:active {
  background-color: #4b4b4b;
  color: white;
}
input[type=text], 
input[type=password],
select,
textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
  
input[type=submit] {
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: auto;
}
  
input[type=submit]:hover {
  background-color: #45a049;
  
}

.form-container {
    width: 80%;
    margin: 0 auto;
    padding-top: 20px;
}

.navbar-nav {
    margin-right: 10px !important;
}
