input[type=text], 
input[type=password],
select,
textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
  
input[type=submit] {
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: auto;
}
  
input[type=submit]:hover {
  background-color: #45a049;
  
}

.form-container {
    width: 80%;
    margin: 0 auto;
    padding-top: 20px;
}

.navbar-nav {
    margin-right: 10px !important;
}